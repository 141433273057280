import React from 'react';
import { graphql, StaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import pageStyles from '../../templates/za-nas.module.scss';

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        contentfulAsset(contentful_id: { eq: "7CYaKF54b04JkPKDRa8LLr" }) {
          localFile {
            childImageSharp {
              fluid(maxWidth: 4000, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      // Set ImageData.
      const imageData = data.contentfulAsset.localFile.childImageSharp.fluid;
      return (
        <BackgroundImage
          Tag="section"
          className={`${pageStyles.bgSection} ${pageStyles.bgCoverImg} hero is-large`}
          fluid={imageData}
        >
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-12 has-text-centered">
                  <h1 className={`${pageStyles.title}`}>
                    Фондация за образование, спорт, изкуство "Възможност"
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </BackgroundImage>
      );
    }}
  />
);

export default BackgroundSection;
