import React from "react"
import { graphql } from "gatsby"
import { BLOCKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import BackgroundSection from "../components/imgs/mainBgImage"
import Layout from "../components/layout"
import Head from "../components/head"

export const query = graphql`
  query($slug: String!) {
    contentfulPage(slug: { eq: $slug }) {
      title
      date
      body {
        json
      }
    }
  }
`

const About = props => {
  let float_right = true
  const options = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const alt = node.data.target.fields.title["en-US"]
        const url = node.data.target.fields.file["en-US"].url
        const float_dir = float_right
        float_right = !float_right
        return (
          <img
            style={{ float: float_dir ? "right" : "left" }}
            alt={alt}
            src={url}
          />
        )
      },
    },
  }

  return (
    <Layout>
      <Head title={props.data.contentfulPage.title} />
      <BackgroundSection />
      <div className="columns is-desktop">
        <div className="column is-half-desktop is-6 is-offset-3">
          <section className="hero">
            <div className="hero-body">
              <div className="container">
                <h1 className="title"></h1>
                <h2 className="subtitle"></h2>
              </div>
              <div className="content is-medium">
                {documentToReactComponents(
                  props.data.contentfulPage.body.json,
                  options
                )}
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default About
